const toastQueue = []
let isDisplaying = false

/**
 * @param {string} text the message the toast will display
 * @param {string} [type] sets the warning styling on the toast message for urgent cases
 */
export function toast(text, type = 'default') {
  if (isDisplaying) {
    toastQueue.push({ text, type })
    return
  }

  showToast(text, type)
}

function showToast(text, type) {
  isDisplaying = true
  const toast = document.createElement('div')
  toast.classList.add('toast')
  toast.textContent = text

  const backgroundColor = {
    error: '#ff5722',
    warning: '#ff9800',
    success: '#4caf50',
    default: '#444444',
  }[type]

  toast.style.cssText = `
    z-index: 9999;
    font-family: 'Helvetica Neue', sans-serif;
    position: fixed;
    bottom: -100px;
    left: 20px;
    background-color: ${backgroundColor};
    color: #fff;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-align: left;
    width: 300px;
    transition: bottom 0.2s ease-out;
  `

  document.body.appendChild(toast)

  requestAnimationFrame(() => {
    toast.style.bottom = '20px'
  })

  setTimeout(() => {
    toast.style.bottom = '-100px'
    setTimeout(() => {
      document.body.removeChild(toast)
      isDisplaying = false

      if (toastQueue.length > 0) {
        const nextToast = toastQueue.shift()
        showToast(nextToast.text, nextToast.type)
      }
    }, 300)
  }, 3000)
}
