// @ts-nocheck
import AppEvents from './app-events.js'
const SESSION_GLOBALS = 'ejm-app-session-globals'

export function storeSessionGlobals(globals) {
  window.sessionStorage.setItem(SESSION_GLOBALS, JSON.stringify(globals))
}

export function getSessionGlobals() {
  const stored = window.sessionStorage.getItem(SESSION_GLOBALS)
  return stored && JSON.parse(stored)
}

function hiddenFilter({ hidden }) {
  return !hidden
}

function getServices(orgs) {
  return (
    orgs
      // hide any hidden orgs and their services
      .filter(hiddenFilter)
      .map((org) => {
        return org.services.filter(hiddenFilter).map((service) => {
          service.org = org.name
          return service
        })
      })
      .flat()
  )
}

function getServicesByTags(orgs) {
  return getServices(orgs).reduce((acc, service) => {
    service.categoryTags.forEach((tag) => {
      tag = tag.toLowerCase()
      if (!acc[tag]) acc[tag] = []
      acc[tag].push(service)
    })

    return acc
  }, {})
}

export default class Globals {
  #state = {
    orgs: [],
    services: [],
    servicesByTag: {},
  }

  constructor(init = getSessionGlobals()) {
    const orgs = init?.orgs || []

    this.update({
      orgs,
    })

    this.#state
  }

  get orgs() {
    return this.#state.orgs
  }

  /** @type {ResourceOrgService[]} */
  get services() {
    return this.#state.services
  }

  get servicesByTag() {
    return this.#state.servicesByTag
  }

  update({ orgs = this.#state.orgs }) {
    const services = getServices(orgs)
    const servicesByTag = getServicesByTags(orgs)
    this.#state = {
      orgs,
      services,
      servicesByTag,
    }

    storeSessionGlobals(this.#state)
    this.dispatchUpdate()
  }

  dispatchUpdate() {
    window.dispatchEvent(
      new CustomEvent(AppEvents.GLOBALS_UPDATED, {
        detail: this.#state,
      })
    )
  }

  /**
   * @function onUpdate
   * @description Adds an event listener for global updates
   * @param {(e: CustomEvent) => void} callback - The function to be called on update event
   * @returns {Function} - Cleanup function to remove the event listener when called
   */
  onUpdate(callback) {
    window.addEventListener(AppEvents.GLOBALS_UPDATED, callback)

    return () => window.removeEventListener(AppEvents.GLOBALS_UPDATED, callback)
  }
}
