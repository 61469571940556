import { toast } from '../shared/toast.js'
import routes from './routes.js'

/** @type {PermissionCheck} */
export function userIsLoggedIn(options) {
  const isLoggedIn = !!options.sessionUser?.email

  if (!isLoggedIn) {
    toast('Please log in to view this page.')
    return routes.LOGIN
  }

  return null
}

/** @type {PermissionCheck} */
export function userIsAdmin(options) {
  // if kind property exists then user is logged in
  return options.sessionUser?.kind == 'Administrator' ? null : routes.LOGIN
}
